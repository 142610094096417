import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "option", "submitButton", "feedback" ]
  
  choose(event) {
    this.submitButtonTarget.classList.add('green')
    this.feedbackTarget.classList.add('active')
  }
  
  nextQuestion() {
    Turbolinks.visit("/theory/exercises/14/questions/1", {action: "advance"})
  }
  
}
