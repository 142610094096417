import { Controller } from "stimulus"
import { debounce } from "debounce"

export default class extends Controller {
  static targets = [ "placeholder" ]

  submit() {
    this.startLoading()
    setTimeout(function() {
      this.element.querySelector("input[type='submit']").click()  
    }.bind(this), 2000)
  }

  startLoading() {
    this.placeholderTarget.innerHTML = `
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    `
  }

}