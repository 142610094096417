import { Controller } from "stimulus"
import moment from "moment"
import "moment/locale/nl"

export default class extends Controller {
  static targets = [ "lessontypeId", "duration", "durationForm", "instructorIds", "lessonId", "date", "suggestions", "modal", "confirmLessontype", "confirmDate", "confirmTimeslot", "confirmLocation", "confirmInstructor", "location", "locationSelect", "day", "lessonsOnDate" ]

  connect() {
    this.renderDurations()
    this.getSuggestions()
    this.renderIndicators()
    this.getLessonsOnDate()
  }

  getSuggestions() {
    // Reset suggestions
    this.clearTimes()
    this.suggestionsTarget.innerHTML = "<li><em class='text-muted'>Beschikbaarheid ophalen...</em></li>"

    // Fetch suggestions as JSON
    fetch(this.suggestionsUrl)
      .then(response => response.json())
      .then(function(json) {
        if (json.disabled_suggestions && json.disabled_suggestions.length > 0) {
          this.suggestionsTarget.innerHTML = ""
          this.renderDisabledTimeOptions(json.disabled_suggestions)
          this.suggestionsTarget.insertAdjacentHTML("afterbegin", `<li><div class='text-brand-orange mb-2'>${json.message}</div></li>`)
        } else if (json.length > 0) {
          this.suggestionsTarget.innerHTML = ""
          this.renderTimeOptions(json)
          
          // Select first option by default
          this.suggestionsTarget.querySelector("input[type='radio']").checked = true
          this.setTimes()
        } else {
          this.renderBlankState()
        }
      }.bind(this))
  }
  
  getLessonsOnDate() {
    this.lessonsOnDateTarget.src = `/dates/${this.date.format("DD-MM-YYYY")}`
  }
  
  renderIndicators() {
    if (this.element.dataset.indicatorsUrl === undefined) return
    
    this.dayTargets.forEach(function(day) {
      day.classList.remove('indicator')
    })
    fetch(`${this.element.dataset.indicatorsUrl}?lessontype_id=${this.lessontypeIdTarget.value}`)
      .then(response => response.json())
      .then(function(json) {
        json.forEach(function(date) {
          this.dayTargets.forEach(function(day) {
            if (day.dataset.date == date) day.classList.add('indicator')
          })
        }.bind(this))
      }.bind(this))
  }
  
  renderDisabledTimeOptions(options) {
    options.forEach(function(time) {
      let start_time = moment.parseZone(time.start_time)
      let end_time = moment.parseZone(time.end_time)
    
      this.suggestionsTarget.insertAdjacentHTML("beforeend", `<li>
        <label class="form-control-radio">
          <span>${start_time.format("HH:mm")} – ${end_time.format("HH:mm")}</span>
          <div class="text-muted">${time.instructors[0].name}</div>
        </label>
      </li>`)
    }.bind(this))
  }

  renderTimeOptions(options) {
    options.forEach(function(time) {
      let start_time = moment.parseZone(time.start_time)
      let end_time = moment.parseZone(time.end_time)

      this.suggestionsTarget.insertAdjacentHTML("beforeend", `<li>
        <label class="form-control-radio">
          <input type="radio" name="timeslot" value="${start_time.format("HH:mm")}" data-action="reservation#setTimes" data-start-time="${start_time.format("HH:mm")}" data-end-time="${end_time.format("HH:mm")}" data-lesson-id="${time.lesson_id}" data-instructor-id="${time.instructors[0].id}" data-instructor-name="${time.instructors[0].name}" />
          <span>${start_time.format("HH:mm")} – ${end_time.format("HH:mm")}</span>
          <div class="text-muted">${time.instructors[0].name}</div>
        </label>
      </li>`)
    }.bind(this))
  }
  
  clearTimes() {
    this.element.elements["start_time"].value = ""
    this.element.elements["end_time"].value = ""
    this.element.elements["instructor_ids"].value = ""
    this.element.elements["lesson_id"].value = ""
    this.setConfirmModal()
  }

  setTimes() {
    let timeslot = this.element.querySelector(`input[name="timeslot"]:checked`)
    this.element.elements["start_time"].value     = timeslot.dataset.startTime
    this.element.elements["end_time"].value       = timeslot.dataset.endTime
    this.element.elements["instructor_ids"].value = timeslot.dataset.instructorId
    if (timeslot.dataset.lessonId != "undefined") {
      this.element.elements["lesson_id"].value = timeslot.dataset.lessonId
    } else {
      this.element.elements["lesson_id"].value = ""
    }
    this.instructor = timeslot.dataset.instructorName
    this.setConfirmModal()
  }

  setConfirmModal() {
    this.confirmLessontypeTarget.innerText = this.lessontypeIdTarget.options[this.lessontypeIdTarget.selectedIndex].innerText
    this.confirmInstructorTarget.innerText = this.instructor
    this.confirmDateTarget.innerText = this.date.format("dddd D MMMM YYYY")
    this.confirmTimeslotTarget.innerText = this.timeslot
    this.confirmLocationTarget.innerText = this.location
  }

  renderBlankState() {
    this.suggestionsTarget.innerHTML = "<em class='text-muted'>Geen beschikbaarheid op deze dag</em>"
  }

  renderDurations() {
    let currentDuration = this.durationTarget.value
    this.durationTarget.innerHTML = ""
    let durations = this.lessontypeIdTarget.options[this.lessontypeIdTarget.selectedIndex].dataset.durations.split(",")

    this.durationTarget.insertAdjacentHTML("beforeend", `<option>Geen voorkeur</option>`)
    durations.forEach(function(duration) {
      this.durationTarget.insertAdjacentHTML("beforeend", `<option value="${duration}">${duration} min</option>`)
    }.bind(this))

    if (this.durationTarget.querySelectorAll("option").length <= 2) {
      this.durationFormTarget.style.display = "none"
    } else {
      this.durationFormTarget.style.display = "flex"
    }
  }

  changeLocation() {
    let select = this.locationSelectTarget
    if (select.value == "Anders...") {
      select.style.display = 'none'
      this.locationTarget.value = ""
      this.locationTarget.style.display = 'block'
    } else {
      this.locationTarget.value = select.value
    }
    this.setConfirmModal()
  }

  openModal() {
    if ((!this.hasLocationTarget || !this.locationTarget.required || this.locationTarget.value.length > 0) && this.startTime.length > 0 && this.endTime.length > 0) {
      this.modalTarget.style.display = "flex"
    }
  }

  closeModal() {
    this.modalTarget.style.display = "none"
  }

  get location() {
    return this.element.elements["location"]?.value
  }

  get date() {
    return moment(this.element.elements["date"].value, "YYYY-MM-DD")
  }
  
  get startTime() {
    return this.element.elements["start_time"].value
  }
  
  get endTime() {
    return this.element.elements["end_time"].value
  }

  get timeslot() {
    return `${this.startTime} – ${this.endTime} uur`
  }

  get suggestionsUrl() {
    let url = new URL(this.element.dataset.suggestionsUrl)
    Object.keys(this.reservationParams).forEach(key => url.searchParams.append(key, this.reservationParams[key]))
    return url
  }

  get reservationParams() {
    return {
      lessontype_id: this.lessontypeIdTarget.value, 
      date: this.element.elements["date"].value, 
      // instructor_id: this.instructorIdTarget.value, 
      duration: this.durationTarget.value
    }
  }

}