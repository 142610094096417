import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["priceButton"]

  changeQuantity(event) {
    let price = event.currentTarget.dataset.price
    this.priceButtonTarget.innerText = price
  }

}