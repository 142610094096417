import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    if (!this.languageMismatch) this.element.remove()
  }
  
  get browserIsDutch() {
    return this.language.toLowerCase().includes("nl")
  }
  
  get currentLocale() {
    return this.element.dataset.currentLocale
  }
  
  get language() {
    return navigator.language || navigator.userLanguage
  }
  
  get languageMismatch() {
    return !this.browserIsDutch && this.currentLocale == "nl"
  }
  
}