import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Turbo
import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

// Stimulus application
const application = Application.start()
const shared = require.context("./shared_app", true, /\.js$/)
const context = require.context("./leerling_app", true, /\.js$/)
application.load(definitionsFromContext(shared))
application.load(definitionsFromContext(context))
