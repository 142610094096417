import { Controller } from "stimulus"
import { Droppable } from '@shopify/draggable'

export default class extends Controller {
  static targets = [ "draggable", "droppable", "indexField", "indexOrderField"]
  
  connect() {
    this.droppable = new Droppable(this.element, {
      draggable: '.draggable',
      dropzone: '.droppable',
      delay: 0
    })
    this.droppable.on("droppable:dropped", this.dropped.bind(this))
  }
  
  dropped(event) {
    let dropzone = event.dropzone
    let selectedTarget = dropzone.dataset.index
    
    let dropEvent = new Event('dropped')
    this.element.dispatchEvent(dropEvent)
    
    if (this.hasIndexFieldTarget) {
      this.indexFieldTarget.value = selectedTarget 
    }
    
    if (this.hasIndexOrderFieldTarget) {
      let index = event.data.dragEvent.data.source.dataset.index
      this.indexOrderFieldTargets[index].value = selectedTarget
    }
  }
  
}
