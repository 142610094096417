import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submitButton", "progress" ]
  
  activateSubmit() {
    this.submitButtonTarget.removeAttribute('disabled')
    this.submitButtonTarget.classList.add('green')
  }
  
  setProgress(event) {
    let percent = event.currentTarget.dataset.progress
    let translateX = (100 - percent) * -1
    this.progressTarget.style.transform = `translateX(${translateX}%)`
  }
  
  checking() {
    this.submitButtonTarget.innerText = "Controleren..."
  }
  
}