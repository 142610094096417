import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]
  
  connect() {
    if (this.buttonTarget.nodeName == "input") {
      this.originalLabel = this.buttonTarget.value
    } else {
      this.originalLabel = this.buttonTarget.innerHTML
    }
  }
  
  enable() {
    this.buttonTarget.removeAttribute('disabled')
    this.setButtonLabel(this.originalLabel)
  }
  
  disable() {
    this.buttonTarget.disabled = true
    this.setButtonLabel(this.disabledMessage)
  }
  
  setButtonLabel(content) {
    if (this.buttonTarget.nodeName == "input") {
      this.buttonTarget.value = content
    } else {
      this.buttonTarget.innerHTML = content
    }
  }
  
  get disabledMessage() {
    return this.buttonTarget.dataset.disableWith
  }
  
}